
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
.main {
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 64px;
}
@media screen and (max-width: 1024px) {
  .main {
    padding-top: 110px;
  }
}
