@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Source+Sans+3:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans:wght@400;700&family=Source+Sans+3:wght@200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "BYD_ProductNames";
  font-display: swap;
  src: url("../../../public/fonts/BYD_ProductNames.ttf");
}

@font-face {
  font-family: "CMS BYD";
  font-display: swap;
  src: url("../../../public/fonts/CMS_BYD.ttf");
}

@font-face {
  font-family: "Logo_Consorcio";
  font-display: swap;
  src: url("../../../public/fonts/Logo_Consorcio.ttf");
}

@font-face {
  font-family: "Logo";
  font-display: swap;
  src: url("../../../public/fonts/logo.ttf");
}

@font-face {
  font-family: "LogoGAB";
  font-display: swap;
  src: url("../../../public/fonts/LogoGAB.ttf");
}
